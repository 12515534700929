
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import InfoModal from '@/components/StudyCreation/InfoModal.vue';
import { viabilityActivities } from '@/utils/viability.ts'
export default defineComponent({
  name : 'WatLabPlansTable',
  components:{
    InfoModal
  },
  computed: {
    ...mapGetters([
      'WatLabPlans'
    ])
  },
  props:{
    planName:{
      type: String,
      default: 'GOLD'
    }
  },
  data(){
    return{
      selectedPlan:'GOLD',
      viabilityActivities: viabilityActivities,
      showInfoModal: false
    }
  },
  watch:{
    planName(newValue: any){
      this.selectedPlan = newValue
    }
  },
  methods:{
    changePlan(planName: any){
      this.selectedPlan = planName
      this.$emit('change-plan', this.selectedPlan)
    }
  },
  mounted(){
    this.selectedPlan = this.planName
  }
})
