
import { defineComponent } from 'vue';
import Separation from '@/components/StudyCreation/Separation.vue'
import Input from '~/components/Input/Input.vue'
import CountrySelector from '@/components/StudyCreation/CountrySelector.vue'
import LanguageSelector from '@/components/StudyCreation/LanguageSelector.vue'
import SelectionBox from '@/components/StudyCreation/SelectionBox.vue'
import { FocusSubProductTypes } from '@/utils/constants'

export default defineComponent({
  name : 'WatFocusStep2',
  components:{
    Separation,
    Input,
    CountrySelector,
    LanguageSelector,
    SelectionBox
  },
  props:{
    config: {
      type: Object,
      default: ()=>{}
    }
  },
  data(){
    return{
      sessionOptions:[1,2,3,4,5,6],
      participantOptions:[5,6,7,8],
      FocusSubProductTypes:FocusSubProductTypes
    }
  },
  methods:{
    addSessionOption(){
      const lastOption = this.sessionOptions[this.sessionOptions.length - 1]
      this.sessionOptions.push(lastOption + 1)
    }
  }
})
