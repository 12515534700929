import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-169c9119"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "StudyType--Element" }
const _hoisted_2 = { class: "StudyType--Content" }
const _hoisted_3 = { class: "StudyType--Info" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudyIcon = _resolveComponent("StudyIcon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["StudyType", {'active':_ctx.active, 'disabled': _ctx.disabled}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_StudyIcon, {
          type: _ctx.type,
          subType: _ctx.subType,
          class: "StudyType--Icon"
        }, null, 8, ["type", "subType"]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.subType?.length < 1)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.$filters.formatProductType(_ctx.type)), 1))
            : _createCommentVNode("", true),
          (_ctx.subType?.length > 1)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.$filters.formatProductSubType(_ctx.subType)), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
        ])
      ]),
      _createVNode(_component_Button, { class: "Gradient" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('suite_action_select')), 1)
        ]),
        _: 1
      })
    ])
  ], 2))
}