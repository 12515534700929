
import { defineComponent } from 'vue';
import SelectionBox from '@/components/StudyCreation/SelectionBox.vue'
export default defineComponent({
  name : 'LanguageSelector',
  components:{
    SelectionBox
  },
  props: {
    modelValue: String
  },
  watch:{
    modelValue(newValue){
        this.language = newValue
    }
  },
  data(){
    return{
        language: this.modelValue
    }
  },
  methods:{
    change(language: any){
        this.$emit('update:modelValue', language)
    }
  },
  mounted(){
    const selectedElement: any = document.getElementsByClassName('selected')[0]
    selectedElement.style = 'order:1'
  }
})
