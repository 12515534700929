
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import StudyIcon from '@/components/StudyIcon.vue'
import Button from '@/components/Button.vue'
export default defineComponent({
  name : 'StudyType',
  components:{
    StudyIcon,
    Button
  },
  props:{
    title:String,
    text:String,
    type: String,
    subType: {
      type: String,
      default: ''
    },
    active: Boolean,
    disabled: Boolean
  }
})
