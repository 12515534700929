
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import Button from '@/components/Button.vue'
import RangeSlider from '~/components/RangeSlider/RangeSlider.vue'
import Separation from '@/components/StudyCreation/Separation.vue'
import WatLabPlansTable from '@/components/StudyCreation/WatLabPlansTable.vue'
import store from '@/store'
import * as _ from "lodash";

export default defineComponent({
  name : 'WatLabStep2',
  components:{
    Button,
    RangeSlider,
    Separation,
    WatLabPlansTable
  },
  props:{
    duration:{
      type: Number,
      default: 1
    },
    planName:{
      type: String,
      default: 'GOLD'
    },
    isMultiCommunityPlan:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      durationSwitched: false,
      durationData: 1,
      lastDuration: 1
    }
  },
  watch:{
    duration(newValue: any){
      this.durationData = newValue
    }
  },
  methods:{
    async fetchPlansData(){
      await store.dispatch('fetchWatLabPlans')
    },
    switchDuration(){
      this.durationSwitched = !this.durationSwitched
      if(this.durationSwitched){
        this.lastDuration = this.duration
        this.$emit('duration-updated', 12)
      }
      else {
        this.durationData = this.lastDuration
        this.$emit('duration-updated', this.durationData)
      }
    },
    durationUpdated(){
      this.$emit('duration-updated', this.durationData) 
    }
  },
  mounted(){
    if(!this.isMultiCommunityPlan) this.fetchPlansData()
    else{
      this.$emit('change-plan', 'MULTICOMMUNITY')
    }
    this.durationData = this.duration
  }
})
